@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }

  /* Hide specific elements */
  #login-popup .absolute.top-3,
  #login-popup .flex.items-center.p-1 {
    display: none !important;
  }
  /* Hide the buttons */
  .button-to-hide {
    display: none;
  }

  /* Hide the date and time in the header */
  .header-date-time {
    display: none;
  }

  /* Hide the URL path in the footer */
  .footer-url {
    display: none;
  }
}
