
.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: "bold";
  font-style: normal;
}


/* Dashboard css code  */
.rbc-header{
color: rgb(73, 77, 107);
padding: auto;
display: flex;
align-items: center;
justify-content: center;
background-color: white;
height: 55px !important;
border-radius: 5px;
}
.rbc-allday-cell{
margin-top: 20px;
}
.rbc-time-header-cell .rbc-today{
background-color: rgb(73, 77, 90);
color: white;
}
.rbc-time-slot{
  display: flex;
color: rgb(73, 77, 107);
z-index: 1;
}
.rbc-time-slot:not(.rbc-today .rbc-time-slot){
  display: inline;
background-color: white;
}
.rbc-day-slot .rbc-time-slot {
display: none;
border-radius: 8px;
overflow: hidden;
}
.rbc-toolbar button:nth-child(1) {
background-color: rgb(73, 77, 107);
color: white;
margin-right: 3px;
border-radius: 4px;
}
.rbc-toolbar button:nth-child(2) {
background-color: rgb(73, 77, 107);
color: white;
margin-right: 3px;
}
.rbc-toolbar button:nth-child(3) {
content: "jj";
background-color:rgb(73, 77, 107);
color: white;
margin-right: 3px;
}
.rbc-toolbar button:nth-child(4) {
background-color:rgb(73, 77, 107);
color: white;
margin-right: 3px;
}
.rbc-toolbar button:nth-child(4):hover {
background-color: rgb(30, 44, 107);
color: white;
}
.rbc-day-view .rbc-timeslot-group,
.rbc-time-column .rbc-time-slot {
border: none;
}

/* Remove grid lines from the calendar */
.rbc-timeslot-group {
display: none; /* Hides the grid lines */
}

/* Alternatively, if you want to keep the structure but hide the lines visually */
.rbc-time-slot {
border: none; /* Removes border from time slots */
background-color: transparent; /* Make background transparent */
}

/* Optional: If you want to adjust the padding or margins */
.rbc-event {
padding: 5px; /* Adjust padding as needed */
margin: 0; /* Remove margins if necessary */
z-index: 2;
}



/* calendarStyles.css */

/* Default styles */
.rbc-toolbar {
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: column;
}

.rbc-toolbar-label {

font-size: 20px;
font-weight: 500;
align-self: center;
}

.rbc-toolbar {
display: flex;
flex-direction: column;
align-items: flex-end; /* Align items to the right */
position: relative; /* Position in the top-right corner */
}
.rbc-month-view,
.rbc-time-view,
.rbc-agenda-view {
border-radius: 10px; /* Apply the same border radius to the view containers */
overflow: hidden; /* Ensure content respects the border radius */
}

.rbc-event {
border: none; /* Remove border */
box-shadow: none; /* Remove shadow if any */
}

/* Optional: Adjust padding or margin if needed */
.rbc-event {
padding: 5px; /* Adjust as needed */
margin: 0; /* Remove margin if necessary */
}



.rbc-btn-group {
display: flex;
gap: 10px; /* Space between the buttons */
margin-bottom: 3px; /* Space between the button groups */
margin-left: 40px;
flex-direction: column;
align-self: flex-end;
}

.rbc-toolbar .rbc-btn-group button {
border: none; /* Remove border */
transition: background-color 0.3s; /* Smooth transition for background color */
}


.rbc-btn-group button {
margin-bottom: 5px;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
.rbc-toolbar {
  justify-content: center; /* Center alignment for small screens */
}

.rbc-btn-group {
  justify-content: center; /* Center alignment for buttons on small screens */
}
}

/* Media query for large screens */
@media screen and (min-width: 769px) {
.rbc-btn-group {
  justify-content: flex-end; /* Align buttons to the right for large screens */
}
}


/* Calendar.css */
.react-calendar {
border: none;
padding-bottom: 4px;
}

.react-calendar__tile {
max-width: 100%;
padding: 0.75rem 0;
text-align: center;
}

.react-calendar__tile--active {
background: rgb(73, 77, 107); /* Tailwind's blue-600 color */
color: white;
border-radius: 50%;
}
.react-calendar__tile.highlight {
background-color: rgb(73, 77, 107); /* Tailwind's blue-600 color */
color: white;
border-radius: 50%;
}
.highlight {
background-color: rgb(73, 77, 107); /* Tailwind's blue-600 color */
color: white;
border-radius: 50%;
}
.react-calendar__tile.highlight {
background-color: rgb(73, 77, 107); /* Tailwind's blue-600 color */
color: white;
border-radius: 50%;
}
.react-calendar__tile--now {
background: #dbeafe; /* Tailwind's blue-100 color */
}
/* Remove borders from day cells in the month, week, and day views */
.rbc-day-bg,
.rbc-month-row,
.rbc-time-slot,
.rbc-time-content > * > .rbc-day-slot {
  border: none !important;  /* Ensures no borders in day and time cells */
}

/* Remove borders from time slots (week and day views) */
.rbc-time-slot {
  border-top: none !important;
  border-bottom: none !important;
  background-color: transparent !important;
}

/* Remove vertical lines in the week and day views */
.rbc-day-slot {
  border-right: none !important;
}

/* Remove horizontal lines in the month view */
.rbc-month-view .rbc-row-bg .rbc-day-bg {
  border: none !important;
}

/* Remove header borders */
.rbc-header {
  border: none !important;
}

/* Remove the border lines around the entire calendar grid */

/* Remove borders from the time header in day and week views */
.rbc-time-header-cell {
  border-right: none !important;
  border-bottom: none !important;
}

/* Optional: Remove any remaining lines in the time grid */
.rbc-timeslot-group {
  border-top: none !important;
  background-color: transparent !important;
   border-right: none !important;
  border-bottom: none !important;
}

/* Ensure all events remain visible and have no border */
.rbc-event {
  box-shadow: none !important; /* Remove shadows if any */
}

/* Calendar toolbar optimization */
.rbc-toolbar {
  border-bottom: none;  /* Remove toolbar bottom border if present */
}

/* Optional: Remove cell lines on the agenda view */
.rbc-agenda-view table tbody tr td {
  border: none !important;
}

/* Optional: Style for responsiveness */
@media screen and (max-width: 768px) {
  .rbc-toolbar {
    flex-direction: column;
  }
}


