@tailwind base;
@tailwind components;
@tailwind utilities;

/* reset.css */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Target Firefox specifically */
@-moz-document url-prefix() {
  input[type=number] {
    appearance: textfield;
  }
}

.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: "weight";
  font-style: normal;
}

.goog-te-banner-frame.skiptranslate, .goog-te-gadget-simple
 img{
   display: none;
}
.goog-tooltip {
  display: none !important;
}
.goog-tooltip:hover { 
  display: none !important;
}
.goog-text-highlight{
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none !important;
}
body{
  top: 0px   !important;
}



.rbc-toolbar .rbc-btn-group:first-of-type {
  display: none;
}
/* .rbc-day-slot .rbc-time-slot {
  border-radius: 8px;
  overflow: hidden;
}
.rbc-toolbar button:nth-child(1) {
  background-color: aqua;
  color: white;
  margin-right: 3px;
  border-radius: 4px;
}
.rbc-toolbar button:nth-child(2) {
  background-color: rgb(17, 77, 77);
  color: white;
  margin-right: 3px;
}
.rbc-toolbar button:nth-child(3) {
  content: "jj";
  background-color: blueviolet;
  color: white;
  margin-right: 3px;
}
.rbc-toolbar button:nth-child(4) {
  background-color: rgb(150, 148, 24);
  color: white;
  margin-right: 3px;
}
.rbc-toolbar button:nth-child(4):hover{
  background-color: rgb(30, 44, 82);
  color: white;
}
.rbc-day-view .rbc-timeslot-group,
.rbc-time-column .rbc-time-slot {
  border: none;
}

.rbc-toolbar{
  display: none;
} */

